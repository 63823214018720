import React from 'react';

import LandingPage from './landing';


import 'semantic-ui-css/semantic.min.css'




// const IndexPage = (props:any) => {
//     return LandingPage;
// }

export default LandingPage;